import React from "react";
import "./contactForm.css";
export default function ContactForm() {
  return (
    <div class="main-form-container" id="contact">
       
      <form action="/action_page.php">
      <div className="conatct-form-title">Contact US</div>
        <div className="form-input-container">
          <label for="fname">First Name</label>
          <input
            type="text"
            id="fname"
            name="firstname"
            placeholder="Your name.."
          />
        </div>
        <div className="form-input-container">
          <label for="lname">Last Name</label>
          <input
            type="text"
            id="lname"
            name="lastname"
            placeholder="Your last name.."
          />
        </div>

        <div className="form-input-container">
          <label for="subject">Massgae</label>
          <textarea
            id="subject"
            name="subject"
            placeholder="Write something.."
          ></textarea>
        </div>
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
}
