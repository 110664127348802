import React from "react";
import powerNoxLogo from "../../assets/img/logo 2.png";
import "./clientSection.css";
import clientLogo1 from "../../assets/img/company_1-min.png";
import clientLogo2 from "../../assets/img/company_2-min.png";
import clientLogo3 from "../../assets/img/company_3-min.png";
import clientLogo4 from "../../assets/img/company_4-min.png";
import clientLogo5 from "../../assets/img/company_5-min.png";
import clientLogo6 from "../../assets/img/company_6-min.png";
export default function ClientSection() {
  return (
    <div className="client-sect-container">
      <center className="client-setion-title">
      Clients we are proud to work with</center>
      <div className="client-logo-container">
        <div className="logo-img-container">
          <img
            className="client-logo-img"
            src={clientLogo1}
            alt="client-logo-img"
          ></img>
        </div>
        <div className="logo-img-container">
          <img
            className="client-logo-img"
            src={clientLogo2}
            alt="client-logo-img"
          ></img>
        </div>
        <div className="logo-img-container">
          <img
            className="client-logo-img"
            src={clientLogo3}
            alt="client-logo-img"
          ></img>
        </div>
        <div className="logo-img-container">
          <img
            className="client-logo-img"
            src={clientLogo4}
            alt="client-logo-img"
          ></img>
        </div>
        <div className="logo-img-container">
          <img
            className="client-logo-img"
            src={clientLogo5}
            alt="client-logo-img"
          ></img>
        </div>
        <div className="logo-img-container">
          <img
            className="client-logo-img"
            src={clientLogo6}
            alt="client-logo-img"
          ></img>
        </div>
      </div>
    </div>
  );
}
