import React from "react";
import "./footer.css";
import ContactForm from "../form/contactForm";

export default function Footer() {
  return (
    <>
      <div>
        <div className="footer-main-container">
          <ContactForm></ContactForm>
          <div className="second-col-footer-conatiner">
            <div className="column-third-info">
              <div className="company-detail-section-title"> Contact info</div>
              <div className="company-detail-text">
                <div className="detail-text">
                  We are a leading web development firm crafting responsive, scalable
                  websites, optimizing user experiences, and leveraging
                  cutting-edge technologies to drive digital success
                </div>
              </div>
              {/* <div className="company-detail-text">
              <div className="detail-text">Call Us: 1234567890</div>
            </div> */}
              <div className="company-detail-text">
                <div className="detail-text">Email Us: info@powernox.com</div>
              </div>
            </div>
            <div className="column-third-info">
              <div className="company-detail-section-title"> Explore</div>
              <div className="quick-links">Home</div>
              <div className="quick-links">About Us</div>
              <div className="quick-links">Service</div>
              <div className="quick-links">Contact Us</div>
            </div>
          </div>
        </div>
        <center className="copywriite-footer">
          @Powernox.com All rights reserved.
        </center>
      </div>
    </>
  );
}
