

import "./App.css";
import Homepage from "./pages/homepage/homepage";


function App() {
  
  return (
    <div>
      <Homepage></Homepage>
    </div>

  );
}




export default App;
