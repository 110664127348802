import React from "react";
import "./about.css";
export default function About() {
  return (
    <div id="service">
      <center>
        <h1 className="section-title">What We Do </h1>
      </center>
      <div className="about-cards-main-container">
        <div className="about-service-card-container">
          <div className="card">
            <div className="card-name">Front End Development</div>
          </div>
          <div className="card">
            <div className="card-name">Back End Development</div>
          </div>
          <div className="card">
            <div className="card-name">App Development</div>
          </div>
          <div className="card">
            <div className="card-name">SEO</div>
          </div>
          <div className="card">
            <div className="card-name">Digital Marketing</div>
          </div>
          <div className="card">
            <div className="card-name">Social Media Marketing</div>
          </div>
        </div>
      </div>
    </div>
  );
}
