import React, { useState } from "react";

import logoImg from "../../assets/img/logo 2.png";
import "./header.css";
import whiteMenuIcon from "../../assets/img/white-menu.png";
export default function Header() {
  const [showSidebar, setShowSidebar] = useState(false);

  const onMenuClickHandler = () => {
    setShowSidebar(true);
  };

  const onCancelClickHandler = () => {
    setShowSidebar(false);
  }
  return (
    <div className="main-header-container">
      <div className="logo-container">
        <img className="logo-img" src={logoImg} alt="logo-img"></img>
      </div>
      <div className="header-options-container">
        <a href="#home">
          <div className="header-option">Home</div>
        </a>
        <a href="#service">
          <div className="header-option">Services</div>
        </a>        
        <a href="#about-company">
          <div className="header-option">About</div>
        </a>
        <a href="#contact">
          <div className="header-option">Contact</div>
        </a>
      </div>
      <div className="responsive-menu-container">
        <img
          src={whiteMenuIcon}
          className="menu-icon-img"
          onClick={onMenuClickHandler}
          alt="menu-icon-img"
        ></img>
        <div   className={showSidebar ? "responsive-fixed-sidebar-container" : "hide"}>
          <div className="close-icon-sidebar" onClick={onCancelClickHandler}>X</div>

          <a href="#home">
            <div className="responsive-menu-option" onClick={onCancelClickHandler}>Home</div>
          </a>
          <a href="#about-company">
            <div className="responsive-menu-option" onClick={onCancelClickHandler}>About</div>
          </a>
          <a href="#contact">
            <div className="responsive-menu-option" onClick={onCancelClickHandler}>Contact</div>
          </a>
          <a href="#service">
            <div className="responsive-menu-option" onClick={onCancelClickHandler}>Service</div>
          </a>
        </div>
      </div>
    </div>
  );
}
