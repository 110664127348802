import React from "react";
import "./aboutCompany.css";
import aboutSecHighightIMg from "../../../src/assets/img/partnerWithUsImg.png";
export default function AboutCompany() {
  return (
    <>
      <div className="about-company-info-container" id="about-company">
        <div className="section-title"> Who We Are</div>
        <div className="about-comapny-main-content">
          <div className="about-company-img-container">
            <div className="behind-card"></div>
            <img
              className="about-compny-sec-img"
              src={aboutSecHighightIMg}
              alt="aboutSecHighightIMg"
            ></img>
          </div>
          <div className="summary-text-container">
            <div className="summary-text">
              We are a team of experts who specialize in creating and designing
              high-quality websites and web applications. We offer a range of
              services including website development, e-commerce solutions,
              mobile app development, and digital marketing. We works closely
              with clients to understand their needs and requirements, creating
              customized solutions that are user-friendly, visually appealing,
              and functional. With a strong focus on customer satisfaction, the
              company utilizes the latest technologies and industry best
              practices to ensure that every project is executed to perfection.
              We are committed to delivering exceptional results and helping
              businesses establish a strong online presence, whether it's a
              small business or a large enterprise. With our expertise,
              businesses can reach their target audience effectively and achieve
              their goals in the digital world.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
