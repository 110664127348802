import React from "react";
import bannerImg from "../../assets/img/heroImg2.png";
import "./heroSection.css";
export default function HeroSection() {
  return (
    <div className="banner-img-conatiner" id="home">
      <img className="banner-img" src={bannerImg} alt="banner-img"></img>
    </div>
  );
}
