import React from "react";
import Header from "../../components/header/header";
import HeroSection from "../../components/heroSection/heroSection";
import About from "../../components/about/about";
import "./homepage.css";
import AboutCompany from "../../components/aboutCompany/aboutCompany";
import Footer from "../../components/footer/footer";
import ClientSection from "../../components/clientsSection/clientSection";
import Testimonial from "../../components/testimonial/testimonial";

export default function Homepage() {
  return (
    <div className="homepage-main-container">
      <Header></Header>
      <HeroSection></HeroSection>
      <About></About>
      <AboutCompany></AboutCompany>

      <ClientSection></ClientSection>
      <Testimonial></Testimonial>
      <Footer></Footer>
    </div>
  );
}
