import React from "react";
import "./testimonial.css";
import qoutesIcon from "../../assets/img/qoutescon.png";
export default function Testimonial() {
  return (
    <div className="testimonial-main-container">
        <center className="section-title">What our clients say</center>
      <div className="testimony-card-container">
        <div className="testimony-main-card">
          <div className="testimonial-card">
            <div className="quote-icon-container">
              <img className="qoutes-icon-start" src={qoutesIcon} alt="qoutes-icon-start"></img>
            </div>
            <div className="testimonial-text">
              Top-notch IT solutions! Their unparalleled expertise and timely
              assistance have been the cornerstone of our seamless operations. A
              definitive must-have partner!
            </div>
            <div className="client-testimonial-name">--Manish Yadava</div>
            <div className="quote-end-icon-container">
              <img src={qoutesIcon} className="qoutes-icon-end" alt="qoutes-icon-start"></img>
            </div>
          </div>
        </div>
        <div className="testimony-main-card">
          <div className="testimonial-card">
            <div className="quote-icon-container">
              <img className="qoutes-icon-start" src={qoutesIcon} alt="qoutes-icon-start"></img>
            </div>
            <div className="testimonial-text">
              Their IT services transformed our business! Exceptional support,
              innovative solutions, and a true partner in our technology journey
            </div>
            <div className="client-testimonial-name">--Amit Nalavade</div>
            <div className="quote-end-icon-container">
              <img src={qoutesIcon} className="qoutes-icon-end" alt="qoutes-icon-start"></img>
            </div>
          </div>
        </div>
        <div className="testimony-main-card">
          <div className="testimonial-card">
            <div className="quote-icon-container">
              <img className="qoutes-icon-start" src={qoutesIcon} alt="qoutes-icon-start"></img>
            </div>
            <div className="testimonial-text">
              Exceptional IT services! They've transformed our operations,
              ensuring seamless efficiency and security. Highly recommended for
              all your tech needs.
            </div>
            <div className="client-testimonial-name">--Chris D.</div>
            <div className="quote-end-icon-container">
              <img src={qoutesIcon} className="qoutes-icon-end" alt="qoutes-icon-start"></img>
            </div>
          </div>
        </div>

        <div className="testimony-main-card">
          <div className="testimonial-card">
            <div className="quote-icon-container">
              <img className="qoutes-icon-start" src={qoutesIcon} alt="qoutes-icon-start"></img>
            </div>
            <div className="testimonial-text">
              "The IT services provided by this company have been a game-changer
              for our business. Reliable, efficient, and truly indispensable."
            </div>
            <div className="client-testimonial-name">--Vansh Sharma</div>
            <div className="quote-end-icon-container">
              <img src={qoutesIcon} className="qoutes-icon-end" alt="qoutes-icon-start"></img>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
